.career-bear-model {
    max-width: 200px;
    width: 30vw;
    min-width: 150px;
    right: 10%;

}

.career-bear-model img {
    object-fit: cover;
    width: 100%;
    height: auto;
    cursor: pointer;
}