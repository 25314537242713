.manillafolder{
    background-color: #f2f2f2;
    padding: 50px; 
    width: 1020px; 
    min-height: fit-content;
    max-height: 2500px;
    margin: 115px; 

    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

.tab-content{
    max-width: 1020px;
    max-height: 2000px; 
  }
  
.tab-name {
    font-weight: bold;
    color: #6c4c41;
    max-width: 135px; 
    overflow: hidden;
    text-align: left; 
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  
  
  .personality-tab5{
    max-height: 750px;
  }
  
  .column-container{
    align-items: left;
    /* display: block; */
    padding: 10px;
  }
  
  .tabs-content{
    text-align: left;
    padding: 10px;
    min-height: 400px; 
    max-height: 1300px;
    max-width: 1275px;
  }
  
  .nav-tabs .nav-link{
    margin-bottom: -5px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-top:5px;
    padding-bottom:5px;
    max-height: 40px ;
    width: 160px;
    
  }

  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    max-height: 100px;
  }

  .nav-item{
    max-width: 175px;
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    height: 40px;
}

.personality-header{
  padding-top: 10px;
}

.personality-header-content{
  margin: 3px;
}

.career-bear-intern-container {
  /* position: absolute;
  display: flex;

  overflow-wrap: normal;

  column-gap: 10%;
  width: 500px;
  height: 150px;

  right: min(300px, 10%);
  bottom: 100px; */

  position: absolute;
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  justify-content: flex-end;

  overflow-wrap: normal;

  column-count: 2;
  column-fill: auto;
  height: 200px;
  width: 480px;
  /* right: min(-450px, 15%); */
  /* bottom: 200px; */
  margin-left: 30%;
  bottom: -50px;

}

.career-bear-intern-container img {
  width: 30%;
}

.text-for-careerbear{
  max-height: 150px;
  text-align: center;
  padding: 15px;
  width: 100%;
  max-width: 400px;
  justify-content: center;
  word-wrap: break-word;

  overflow-y: auto;
  scroll-behavior: auto;

  display: flex;
  flex-direction: column-reverse;


  border-radius: 20px;

  border: 3.5px solid #6c4c41;
  background-color: rgb(255, 255, 249);
}