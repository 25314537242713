.user-page {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-page--content {
    margin: 20vh 10vh 30vh 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user-page--fitz {
    width: 50%;
    min-width: 100px;
}
