.progress-bar-bootstrap {
    position: absolute; 
    left: 0;
    right: 0;
    margin-right: 1vw;
    margin-left: 1vw;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    background-color: #d7c0a8; /*change progressbar color if needed*/

}

.progress-bar-intern {
    position: fixed;
    bottom: 0;
}

.progress-bar-career {
    bottom: -10px ;
}

.custom-progress-bar .progress-bar { 
    background-color: #6c4c41; /*change progressbar color if needed*/
    height: 20px;
    border-radius: 5px;
    -webkit-transition: width 300ms ease;
    transition: width 300ms ease;
}

.progress-bear-image {
    width: 10vh;
    position: absolute;
    transition: 0.5s;
    bottom: 10px;
    transform: rotateY(180deg)

}

.progress-intern-image {
    width: 15vh;
    position: absolute;
    transition: 0.5s;
    bottom: 0;
    transform: rotateY(180deg)
}

.progress-intern-searching-image {
    transition: 0;
    transform: none;
}