.transcript {
    padding: 20px; 
    max-height: 1000px;
    overflow-y: scroll;  
}

.transcript-header {
    margin: 40px;
}

.interaction {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.interaction p {
    text-align: start;
}