.home-page-sign-up-widget {
    border-radius: 25px;
    background-color: #FFF6EC;
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);

    min-height: 30vh;
    min-width: 50vh;
    width: 80%;

    padding: 5vh 5vw;
    margin-block: 5vh;
}

.sign-up-widget--text-content {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.sign-up-widget--text-content p {
    margin: 10px;
    font-size: larger;
}

.sign-up-button {
    margin: 20px
}