.career-tab-containers{
    position: flex;
    max-width: 1275px;

    max-height: fit-content;

    overflow: hidden;
    padding: 20px
    
}

.tab-content {
    padding: 20px;
    width: 100%;
    height: 100%;
    
    display: flex;

    flex-wrap: wrap;
    flex-direction: row;

    justify-content: center;

    column-gap: 40px;
}

.tab-main-content {
    padding: 20px;
    width: 100%;
    height: 600px;
    
    display: flex;

    flex-wrap: wrap;
    flex-direction: column;

    column-gap: 40px;
    padding: 10px
    
}

.tab-content-heading {
    margin-bottom: 40px;
}


.career-section {
    width: 50%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 5px;
}

.career-section-header {
    font-weight: 600;
    text-align: start;
}

.career-section-body {
    text-align: start;
}

.career-requirements-list {
    padding-left: 20px;
    text-align: start;
    list-style-type: disc;
}

.listHeader {
    font-weight: bold;
    list-style: none;
    margin-block: 10px;
}

.sleeping-bear {
    width: 60%;
}

.image-container {
    align-items: center;
}

.finacial-content--metrics {

    margin-top: 50px;
    display: flex;
    flex-direction: row;

    column-gap: 10px;
}

.CareerMetric {
    height: 165px;
    width: 165px;
    background-color: #d7c0a8;

    display: flex;
    flex-direction: column;



    align-items: center;
    justify-content: center;

    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);

    font-family: "Swanky and Moo Moo", cursive;
    font-weight: 700;
    font-style: normal;
    font-size: x-large;

}


.career-bear-disclosure {
    margin-top: 20px;
}

.file-image {
    width: 400px;
}