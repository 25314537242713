.review-container {
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);
    background-color: #FFF6EC;
    border-radius: 10px;
    padding: 50px;
    margin-top: 40px;

    font-size: 22px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.question-answer-container {
    padding: 20px;  
    margin-bottom: 20px;  
    margin-left: 10px;
    background-color: #ece4db; 
    border-radius: 8px;  
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);  

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: start;

    transition: 0.5s;
}

.question-answer-container:hover {
    margin-left: 0;
    margin-right: 10px;
}


.review-header {
    width: 100%;
    height: 50px;
}


.review-header{
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    font-size: 5vh;
}


.question-name {
    font-weight:600;
    color: rgb(105, 77, 60);  
}

.question-change-action {
    min-width: 50px;
    min-height: 50px;
    padding: 0;

    font-size: 20px;
    border-radius: 100%;
    margin-right: 20px;
}

.question-change-action:hover {
    border: solid 1px #6c4c41; 
}

.change-answers-button {
    background-color: #6c4c41;
    color: #FFF6EC;
    min-height: 50px;
    max-height: 100px;
    min-width: 150px;
    padding: 10px 20px;
    cursor: pointer;
}

.answer-text-default {
    font-weight: 100px;
}

.answer-text {
    font-weight: normal; 
}

.actions {
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}