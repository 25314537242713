.App-footer {
    /* background-color:#282c34; */
    background-color: #6c4c41;
    box-shadow: 0px -2.98256px 7.4564px rgba(0, 0, 0, 0.1);
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 15vh;
    min-width: 100%;
    padding: 20px;
  }

  .App-footer-basic .copyright {
    margin-top:15px;
    text-align:center;
    font-size:13px;
    margin-bottom:0;
  }

  .App-footer-basic ul {
    padding:0;
    list-style:none;
    text-align:center;
    font-size:18px;
    line-height:1.6;
    margin-bottom:0;
  }
  
  .App-footer-basic li {
    padding:0 10px;
  }
  
  .App-footer-basic ul a {
    color:inherit;
    text-decoration:none;
    opacity:0.8;
  }

  .App-footer .list-inline-item a{
    color: #f4ece2 ;
  }

  .App-footer a {
    text-decoration: none;
  }
  
  .App-footer a:hover {
    text-decoration: underline;
  }

  .App-footer .disclaimer{
    margin-top:15px;
    text-align:center;
    font-size:13px;
    margin-bottom:15px;
    color: #f4ece2; 
  }