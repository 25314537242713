.home-page-quiz-selection-widget {
    border-radius: 25px;
    background-color: #FFF6EC;
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);
 
    min-height: 30vh;
    min-width: 50vh;
    width: 80%;

    padding: 5vh 5vw;
    margin-block: 5vh;

}

.quiz-selection-widget--text-content {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    column-gap: 10vw;
}

.selection-widget--sub-header {
    font-weight: 500;
}

.quiz-selection-widget--choice-card {
                                                                                                                                                           
    padding: 1vh ;

    width: 50vh;
    height: 50vh;

    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    margin-top: 5%;

    transition: 0.5s;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.quiz-selection-widget--choice-card:hover {
    margin-top: 4%;
}

.choice-card--header {
    font-weight: 500;  
    font-size: x-large; 
}

.choice-card--button {
    width: 50%;
    margin-inline: auto;
    margin-top: 10%;
    background-color: #6c4c41;
}

.quiz-selection-description-text{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10vw;
    padding: 3vb;
}
