.previous-button, .next-button, .review-button {
    background-color: #6c4c41;
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);
    color: #FFF6EC;
    height: 50px;
    min-width: 150px;
}

.buttons {
    position: absolute;
    bottom: 50px; 
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
}

.buttons > button {
    margin: 0 20px;
}

