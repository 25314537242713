.career-tab-containers{
    position: flex;
    padding: 15px;
    max-width: 1275px;
    align-items: center;
}

.careertab-paragraphstyle{
    text-align: left;
    padding:5px;
}

.careertab-paragraphstyle ul {
    list-style-type: disc;
    padding-left: 30px;
}
  
.careertab-paragraphstyle li {
    margin-bottom: 5px;
}

.career-tab--job:hover {
    text-decoration: underline;
}
