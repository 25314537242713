.home-page-api-key-widget {
    border-radius: 25px;
    background-color: #FFF6EC;
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);

    min-height: 30vh;
    min-width: 50vh;
    width: 80%;

    padding: 5vh 5vw;
    margin-block: 5vh;
}

.api-key-widget--text-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 0;
    padding: 15px
}

.api-key-widget--text-content p {
    font-size: larger;
}

.content--fitz-widget {
    padding: 35px;
    display: flex;
    justify-content: center;
}

.content--fitz-widget img {
    max-width: 150px;
}

.fitz-widget--speech {
    background-color: #fff8f3;
    border-radius: 25px;
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);
    margin-inline: 20px;
    padding: 15px;
    height: fit-content;
}

.content--api-key-input {
    background-color: #fff8f3;
    border-radius: 15px;
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;

    max-width: 1000px;
    width: 100%;
    padding: 15px;
}

.api-key-input--input-box {
  background-color: #f4ece2;
  max-height: 50px;
}

.input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: 10s;
}

.clear-key-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.clear-api-key--button {
    font-size: medium;
    min-width: 200px;
    width: 20%;
}

.api-key-input--input-box:focus {
  border: 1px solid #6c4c41;
  box-shadow: none;
  background: #FFF6EC;
}

.input-box--reveal {
    background-color: #f4ece2;
}

.content--submit-button {
    margin: 10px;
    max-height: 50px;
    font-size: 15px;

    text-align: center;
    text-wrap: nowrap;
}

.clear-warning {
    font-size: small;
    margin: 5px
}