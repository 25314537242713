.quiz-data-card {
    border-radius: 15px;
    background-color: #FFF6EC;
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);

    width: 100%;

    transition: 0.5s;

    overflow: hidden;

    padding: 10px
}

.quiz-data-card:hover {
    margin-right: 20px;

}

.quiz-data-card--content {
    padding: 20px;
}

.quiz-data-card--content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;    
}

.quiz-data-card--content h6 {
    text-align: start;
}

.bold-span {
    font-weight: 600;
}