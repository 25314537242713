.career-bear-prompt {
    width: 100%;
    height: 500px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.career-bear-prompt--content {
    padding: 5vw;
    display: flex;
    flex-direction: row;  
    align-items: center;
    justify-content: center;
    flex-wrap:wrap;
    width:100%;
    height: 300px;

    margin-inline: min(10%, 250px);
}

.content--text-box {
    display: flex;
    text-align: center;
    align-self: flex-start;
    justify-content: flex-end;
    width: 60%;
    max-height: 100%;
    padding-inline: 10px;
    transition: 1ms;

    overflow: hidden;
}

.content--text {

    padding: 10px;
    background-color: #fff8f3;
    border-radius: 25px;
    border: 1px solid #6c4c41;

    padding: 5%;
    text-align: center;
    font-size: min(4vw, 12px);

    transition: 5ms;

    max-width: 100%;
    max-height: 70%;

    overflow-y: scroll;
}






.content--user-interface {
    width: 100%;
}