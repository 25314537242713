.user-page-widget-view {
    /* border-radius: 25px;
    background-color: #FFF6EC;
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1); */

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;


    width: 100%;
}

.user-page--greeting {
    margin: 5%;
}

.user-page--widget-view-content {
    margin-block: 5%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
}