ul {
    list-style-type: none;
    padding: 0;
}

.members-page {
    width: 100%;
    height: 100%;
}

.members-page--content {
    min-width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10vh 10vh 30vh 10vh;
}

.members-page--page-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page-buttons {
    max-width: 50%;
    display: flex;
    flex-direction: row;
}