.about-page { 
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 10vh 10vh 0 10vh;
}

.about-page--content {
    width: 80%;
}

.text-content {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
}

.text-content img {
    width: 400px;
    height: 400px;
}