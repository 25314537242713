
/* // {
  //   backgroundImage: `url(${background})`,
  //   backgroundAttachment: "fixed"
  // } */

.App {
  text-align: center;
  display: block;
  min-height: 100vh;
  width: 100%;
  background-color: #f4ece2;

  font-family: "Poppins", sans-serif;
  font-style: normal;
  /* background-color: #d7c0a8; */
}

.App-header {
  background-color: rgb(227, 205, 180);
  min-height: 10%;
  min-width: 100%;

  z-index: 1;

  display: flex;
  position: fixed;
  top: 0;
  flex-direction: row;
  align-items: center;
  font-size: calc(5x + 2vmin);
  color: rgb#282c34;
  text-align: start;
  box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
  transition: 10s;

}

.App-header--heading {
  padding-inline: 20px;
}

.App-content {
  min-height: 90vh;
  min-width: 100vw;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button {
  border-radius: 20px;
  padding: 10px;
  border: none;
  background-color: #6c4c41;
  color: #f4ece2;
  transition: 0.5s;

  padding: 10px 25px 10px 25px;

  font-weight:600;
  font-size: larger;
}

button:hover {
  background-color: #f4ece2;
  color:#6c4c41;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background-color: #6c4c41;
  border-radius: 20px;
}
