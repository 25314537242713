.sign-up-page {
    min-height: 100%;
    width: 100%;   
}

.sign-up-page input {
    background-color: #f4ece2;
}

.sign-up-page input:focus {
    border: 1px solid #6c4c41;
    box-shadow: none;
    background: #FFF6EC;
}

.sign-up-page--content {
    margin: 10vh 10vh 0 10vh;
    padding: 10vh;

    min-width: 500px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.sign-up-page--form-container {
   margin: 5vh; 
   max-width: 40%;
   min-width: 600px;
}

.form-container--content {
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);
    background-color: #FFF6EC;
    border-radius: 10px;

    padding: 50px;
    margin-block: 20vh 10vh 10vh 10vh;
    
    
    font-weight:600;
    font-size: larger;
}

.form--selection {
    font-size: xx-large;
}

.form--row-padding {
    padding: 2%;
}

.form--label-heading {
    text-align: start;
    padding-left: 30px;
}

.form--button-padding {
    padding: 4%;
}

.form--submit-button {
    width: 90%;
}
