.metric-card {
    border-radius: 15px;
    background-color: #FFF6EC;
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);

    width: 150px;
    height: 150px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 10px
}