.basic-questions-page-header {
    font-size: 17px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 40px;
}

.main-header {
    margin: 20px 0 0 0
}

.quiz-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.quiz-details p {
    margin: 0;
}