.detailed-quiz{
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 10vh 10vh 0 10vh;


}

.detailed-quiz--content{

    width: 100%;
    height: 100%;
    
    margin-inline: 5vw;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.content--user-interface {
    margin-bottom: 5%;
}

.user-interface--buttons {
    display: flex;
    flex-wrap: wrap;
    
    flex-direction: row;
    column-gap: 3vw;
    row-gap: 10px;

    padding-top: 20px;
    align-items: center;
    justify-content: center;
    
}

.user-interface--buttons button {
    transition: 1s;
}

.user-prompts--user-input {
    padding: 15px;
    border-radius: 15px;
}

.user-prompts--user-input:focus {
    border: 1px solid #6c4c41;
    box-shadow: none;
}