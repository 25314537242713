.manilla-folder{
  background-color: #f2f2f2;
  padding: 50px; 
  max-width: 1200px; 
  /* min-height: fit-content; */
  max-height: 2500px;
  margin: 115px; 

  /* box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1); */
  /* border-radius: 15px; */
}

.detailed-tab-content{
  max-width: 1020px;
  align-items: left;
}

.detailed-tab-name {
  font-weight: bold;
  color: #6c4c41;
}


.detailed-personality-tab5{
  max-height: fit-content;
}

.detailed-column-container{
  align-items: center;
  display: block;
  padding: 10px;
}

.detailed-tabs-content{
  text-align: left;
  padding: 10px;
  min-height: 400px; 
  max-height: 1300px;
  max-width: 1275px;
}


