.ErrorScreen {

    display: flex;
    align-items: center;
    justify-content: center;
}

.ConfusedFitz {
    width: 50%;
    height: 50%;
}