.metric-display {
    width: 50vw;

    border-radius: 15px;
    background-color: #FFF6EC;
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);

    padding: 5%;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
}

.metrics-data-card--content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    width: 100%;

}