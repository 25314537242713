.personality-tab-container{
    position: flex; 
    padding: 10px;
    max-width: 1275px;
    max-height: 600px;
}

.personality-tab-container-content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    
    height: 100%;
    max-height: 640px;
}

.personality-tab--content{
    flex-basis: 50%;
    flex-wrap: wrap;
    display: flex;
    text-align: left; 
    max-height: 200px;
    max-width: 400px;
    min-height: 100px;
    overflow: hidden;

} 

.personality-row{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    column-gap: 40px;
    row-gap: 10px;
    min-height: 400px;
    max-height: 640px; 
    overflow: hidden;
    max-width: 900px;
}

.personality-header-content{
    max-height: auto;
    min-height: 30px;
}

.personality-text-explaination{
    font-style: italic;
    min-height: 40px;
}

.personality-logic-test{
    max-height: 200px ;
    min-height: 100px;
    overflow: hidden;

}

.career-bear-sticker-container-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    max-width: 300px; 
    justify-content: flex-end;
     
}