.dialog-header {
    background-color: #6c4c41;
    color: #f4ece2;
    font: inherit;

    font-family: "Poppins", sans-serif;
    font-style: normal;
}

.dialog-header button {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding: 0;
}

.dialog-header button:hover {
    color: #6c4c41;
}

.dialog-header button i {
    padding: 0;
}

.dialog-header button:focus {
    border-color: none;
    color: #f4ece2;
}

.dialog-body {
    background-color: #f4ece2;

    font-family: "Poppins", sans-serif;
    font-style: normal;
}

.dialog-switch input:checked {
    color: #6c4c41;
    background-color: #6c4c41;
    border-color: #6c4c41;
}

.dialog-switch input {
    color: #f4ece2;
}

.dialog-switch input:focus {
    box-shadow: none;
}


.dialog-range-input input {
    background-color: #f4ece2;
    background-color: #6c4c41;
}

input[type="range"]::-webkit-slider-thumb {
    background: #6c4c41!important;
  }
  
/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
background: #6c4c41 !important;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
background: #6c4c41 !important;
}