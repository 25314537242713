.question-component--content {
    position: relative; 

    border-radius: 5px;
    background-color: #FFF6EC;
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);

    min-height: 95vh;
    width: 100%;

    min-width: 400px; 

    padding: 1.5vh 1.5vw;
    margin-bottom: 25px;


    display: flex;
    flex-direction: column;
}

.question--main-content {
    display: flex;
    flex-direction: column;
}

.question--heading {
    width: 100%;
    margin: 4% 0 0 0;
    text-align: left;
}

.question--choices {
    margin-block: 20px;
    padding: 10px;
    text-align: left; 
    font-size: 1.75vh;


    width: 100%;
    height: 100%;
}


.question--choices .form-check { /*margin between choices*/
    margin-bottom: 6px;
}

input[type="radio"]:checked {
    background-color: #6c4c41;;
    border: none
}

input[type="radio"]:focus   {
    border: none;
    background-color:#6c4c41;
    box-shadow: none;
}