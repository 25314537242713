.quiz-data-display {
    width: 50vw;

    border-radius: 15px;
    background-color: #FFF6EC;
    box-shadow: 0px 2.98256px 7.4564px 5px rgba(0, 0, 0, 0.1);

    padding: 5%;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
}

.quiz-data-display--header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    align-items: center;
}

.quiz-data--heading {
    padding-left: 20px;
    margin: 0;
}

.no-data-intern {    
    width: 300px;
}