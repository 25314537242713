.app-header {
    /* background-color: #282c34; */
    background-color: #6c4c41;
    height: 10%;
    min-width: 100%;
    padding: 30px;

    z-index: 10000;
  
    display: flex;
    position: fixed;
    top: 0;
    
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-size: calc(5x + 2vmin);
    color: rgb#f4ece2;
    text-align: start;
    box-shadow: 0px 2.98256px 7.4564px rgba(35, 20, 20, 0.1);
    transition: 10s;
  
}

.app-header--heading {
    cursor: pointer;
    color: #f4ece2;
}

.heading--logo {
    width: 15vw;
}

.app-header--profile-container {
    display: flex;
    align-items: center;
    right: 0;
    height: 100%;

}

.profile-container--profile-tab {
    background-color: #6c4c41;    ;
    border-radius: 10px;
    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
    color: #f4ece2;
    padding: 10px;
    font-size: large;
    cursor: pointer;
    transition: .2s;
    overflow: hidden;

    position: absolute;
    right: 40px;
    top: min(25%, 50px);

}

.profile-container--profile-tab:hover {
    color: #6c4c41;
    background-color: #f4ece2;
}

.profile-tab--content {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    transition: .2s;
}

.profile-tab-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 10px;
}

.profile-tab-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 10px;
    row-gap: 10px;
}

.profile-tab-items {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.profile-tab-item:hover {
    text-decoration: underline solid #6c4c41 ;
}

.tab-extended {
    max-height: 400px;
}

.tab-closed {
    height: 50%;
}
