.home-page-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 80%;
    max-width: 90%;

    margin-block: 10%;
}


.home-page-header--heading {
    width: 100%;
    margin: 5% 0 0 0
}