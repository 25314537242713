.detailed-personality-tab-container{
    position: flex; 
    padding: 10px;
    max-width: 1275px;
    max-height: fit-content;
}

.detailed-personality-tab-container-content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    
    height: 100%;
    max-height: 900px;
}

.detailed-personality-tab--content{
    flex-basis: 50%;
    flex-wrap: wrap;
    display: flex;
    text-align: left; 
    max-height: 400px;
    max-width: 400px;
    min-height: 100px;

} 

.detailed-personality-row{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    column-gap: 40px;
    row-gap: 10px;
    min-height: 600px;
    max-height: 900px; 
    max-width: 900px;
}

.detailed-personality-header-content{
    max-height: auto;
    min-height: 30px;
}

.detailed-personality-text-explaination{
    font-style: italic;
    min-height: 40px;
}

.personality-logic-test{
    max-height: 200px ;
    min-height: 100px;
    overflow: hidden;

}